import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { injectGlobal } from 'styled-components'
import { normalize } from 'polished'
import favicon from '../images/site-icon.png'
import preview from '../images/preview.png'
import previewtwitter from '../images/preview-twitter.png'

injectGlobal`
  ${normalize()}
  html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  }
  @import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i');
  @import url('https://rsms.me/inter/inter.css');
  body {
    font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, 'Inter', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    background-color: #1f1f1f;
    @media( min-width: 768px ) {
      font-size: 18px;
    }
  }
  .textarea-field {
    height: 250px;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          link={[
            { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` },
          ]}
        >
          <html lang="en" />

          <meta charset="utf-8" />

          <meta
            name="description"
            content="We Are A Digital Agency That Combine Stunning Design With Killer Conversion."
          />
          <meta
            name="image"
            content={`https://hustleandpraise.com` + preview}
          />

          <meta itemprop="name" content="Hustle & Praise" />
          <meta
            itemprop="description"
            content="We Are A Digital Agency That Combine Stunning Design With Killer Conversion."
          />
          <meta
            itemprop="image"
            content={`https://hustleandpraise.com` + preview}
          />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Hustle & Praise" />
          <meta
            name="twitter:description"
            content="We Are A Digital Agency That Combine Stunning Design With Killer Conversion."
          />
          <meta name="twitter:site" content="@hustleandpraise" />
          <meta name="twitter:creator" content="@hustleandpraise" />
          <meta
            name="twitter:image:src"
            content={`https://hustleandpraise.com` + previewtwitter}
          />

          <meta name="og:title" content="Hustle & Praise" />
          <meta
            name="og:description"
            content="We Are A Digital Agency That Combine Stunning Design With Killer Conversion."
          />
          <meta
            name="og:image"
            content={`https://hustleandpraise.com` + preview}
          />
          <meta name="og:url" content="https://hustleandpraise.com/" />
          <meta name="og:site_name" content="Hustle & Praise" />
          <meta name="og:locale" content="en_IE" />
          <meta name="og:type" content="website" />
        </Helmet>

        {children}
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
